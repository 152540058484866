var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-overlay',{attrs:{"id":"tour-work-summary","show":_vm.processing}},[_c('b-card',[_c('b-row',[_c('b-col',[_c('span',[_vm._v(_vm._s(_vm.$t('Total time worked')))]),_c('h3',[_vm._v(" "+_vm._s(_vm.CONVERT_HM(_vm.workSummary.totalDuration))+" ")]),(_vm.thisWeekDuration || _vm.thisMonthDuration)?_c('p',[_c('span',[_vm._v("This week:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.thisWeekDuration)))]),_c('br'),_c('span',[_vm._v("This month:")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.thisMonthDuration)))]),_c('br')]):_vm._e()]),_c('b-col',[_c('span',[_vm._v(_vm._s(_vm.$t('Productive time'))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.productiveDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-success",attrs:{"value":_vm.CALC_PERCENTAGE({
              total: _vm.workSummary.totalDuration,
              value: _vm.workSummary.productiveDuration
            }),"max":"100","variant":"success"}})],1)]),_c('b-col',[_c('span',[_vm._v(_vm._s(_vm.$t('Unproductive time'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Time spent on unproductive applications'),expression:"'Time spent on unproductive applications'",modifiers:{"hover":true}}],attrs:{"icon":"InfoIcon"}})],1),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.unproductiveDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-danger",attrs:{"value":_vm.CALC_PERCENTAGE({
              total: _vm.workSummary.totalDuration,
              value: _vm.workSummary.unproductiveDuration
            }),"max":"100","variant":"danger"}})],1)]),_c('b-col',[_c('span',[_vm._v(_vm._s(_vm.$t('Neutral time'))+" "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Time spent on neutral applications'),expression:"'Time spent on neutral applications'",modifiers:{"hover":true}}],attrs:{"icon":"InfoIcon"}})],1),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.neutralDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-secondary",attrs:{"value":_vm.CALC_PERCENTAGE({
              total: _vm.workSummary.totalDuration,
              value: _vm.workSummary.neutralDuration
            }),"max":"100","variant":"secondary"}})],1)]),_c('b-col',[_c('span',[_vm._v(_vm._s(_vm.$t('Manual Time'))+" ")]),_c('h3',[_vm._v(_vm._s(_vm.CONVERT_HM(_vm.workSummary.manualDuration)))]),_c('br'),_c('p',[_c('b-progress',{staticClass:"progress-bar-primary",attrs:{"value":"0","max":"100","variant":"primary"}})],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }